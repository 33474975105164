import {
  Box,
  Button,
  Card,
  CardContent,
  CardMedia,
  Grid,
  TextField,
  Typography,
  Input,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  Checkbox,
  TableBody,
  Tabs,
  Tab,
  SelectChangeEvent,
  FormControl,
  Select,
  MenuItem,
  Switch,
  IconButton,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";

import MainContainer from "../../layout/MainContainer";
import { useNavigate } from "react-router-dom";
import { ChangeEvent, useState } from "react";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import CancelIcon from "@mui/icons-material/Cancel";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
interface Role {
  name: string;
  enabled: boolean;
}
const AddSubAdmin = () => {
  const navigate = useNavigate();
  const [role, setRole] = useState("");
  const [rolesData, setRolesData] = useState<Role[]>([]);
  const [newRole, setNewRole] = useState("");

  const [alertMessage, setAlertMessage] = useState("");
  const [showAlert, setShowAlert] = useState(false);
  const [alertType, setAlertType] = useState(0);
  const [image, setImage] = useState<string>("");
  const [fileName, setFileName] = useState<string>("");
  const [value, setValue] = useState<number>(0);
  const handleRoleChange = (event: SelectChangeEvent) => {
    setRole(event.target.value as string);
  };

  const handleRoleDataChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNewRole(event.target.value);
  };

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  function createData(Module: string) {
    return { Module };
  }
  const label = { inputProps: { "aria-label": "Switch demo" } };
  const rows = [
    createData("Manage Users"),
    createData("Manage Courses Content"),
    createData("Manage Challenges"),
    createData("Manage Subscriptions"),
    createData("Revenue Management"),
    createData("Manage Notifications"),
    createData("Reports and Analytics"),
    createData("Manage Customer Support"),
    createData("Manage CMS"),
    createData("Manage FAQs"),
  ];

  const handleAddRole = (event: React.FormEvent) => {
    event.preventDefault();
    if (newRole.trim() !== "") {
      setRolesData([...rolesData, { name: newRole, enabled: true }]);
      setNewRole("");
    }
  };
  const handleToggle = (index: number) => {
    setRolesData((prevRoles) =>
      prevRoles.map((role, i) =>
        i === index ? { ...role, enabled: !role.enabled } : role
      )
    );
  };

  return (
    <>
      <MainContainer>
        <div className="main_loyout">
          <div className="dashboard">
            <h1 className="mn_hdng">Add Sub-Admin</h1>
            <Button
              className="btn btn_primary"
              onClick={() => {
                navigate("/manage-subAdmin");
              }}
            >
              Back
            </Button>
          </div>
          <Card className="cards">
            <Box className="custom_tabs">
              <Box className="flx_sc">
                <Tabs
                  value={value}
                  onChange={handleChange}
                  variant="scrollable"
                  scrollButtons="auto"
                  aria-label="basic tabs example"
                  className="custom_tabs_links"
                >
                  <Tab label="Add Role" {...a11yProps(0)} />
                  <Tab label="Add Sub-admin" {...a11yProps(1)} />
                </Tabs>
              </Box>
              <CustomTabPanel value={value} index={0}>
                <form onSubmit={handleAddRole}>
                  <CardContent sx={{ p: 1 }}>
                    <Grid container spacing={2}>
                      <Grid item lg={4} md={4} sm={4} xs={12}>
                        <Typography className="custom_label">
                          Add Role
                        </Typography>
                        <TextField
                          hiddenLabel
                          type={"text"}
                          name="title"
                          variant="outlined"
                          fullWidth
                          placeholder="Add Role"
                          value={newRole}
                          onChange={(event) =>
                            handleRoleDataChange(event as any)
                          }
                        />
                      </Grid>
                    </Grid>

                    <div className="form_btn">
                      <Button
                        size="large"
                        type="submit"
                        className="btn btn_primary"
                      >
                        Save
                      </Button>
                    </div>
                  </CardContent>
                </form>
                {/* <TableContainer>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>Role</TableCell>
                        <TableCell>Enabled</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {rolesData.map((role, index) => (
                        <TableRow key={index}>
                          <TableCell>{role.name}</TableCell>
                          <TableCell>
                            <Switch
                              checked={role.enabled}
                              onChange={() => handleToggle(index)}
                            />
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer> */}

                <Grid item xs={12}>
                  <TableContainer className="table_container">
                    <Box className="heading"></Box>
                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                      <TableHead>
                        <TableRow>
                          <TableCell className="mn_hdng">S.No.</TableCell>
                          <TableCell className="mn_hdng">Role</TableCell>
                          <TableCell className="mn_hdng">Status</TableCell>
                          <TableCell className="mn_hdng">Delete</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {rolesData?.length ? (
                          rolesData?.map((row, i) => (
                            <TableRow>
                              <TableCell className="mn_hdng">1</TableCell>
                              <TableCell>{row.name}</TableCell>
                              <TableCell>
                                <Switch
                                  defaultChecked
                                  // checked={row.enabled}
                                  // onChange={() => handleToggle(index)}
                                />
                              </TableCell>
                              <TableCell>
                                <IconButton>
                                  <DeleteIcon />
                                </IconButton>
                              </TableCell>
                            </TableRow>
                          ))
                        ) : (
                          <TableBody
                            sx={{
                              padding: "20px",
                              textAlign: "center",
                              width: "100%",
                            }}
                          >
                            <Typography
                              sx={{ padding: "20px", textAlign: "center" }}
                            >
                              {" "}
                              No roles Added
                            </Typography>
                          </TableBody>
                        )}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Grid>
              </CustomTabPanel>
              <CustomTabPanel value={value} index={1}>
                <form>
                  <CardContent sx={{ p: 1 }}>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <Typography className="custom_label">Image</Typography>
                        {image ? (
                          <div className="upload_image_preview">
                            <CardMedia
                              component="img"
                              image={image}
                              alt="photo"
                            />
                            <CancelIcon
                              onClick={() => {
                                setImage("");
                                setFileName("");
                              }}
                            />
                          </div>
                        ) : (
                          <Box className="upload_image">
                            <label htmlFor="icon-button-file">
                              <Input
                                sx={{ display: "none" }}
                                id="icon-button-file"
                                type="file"
                                inputProps={{
                                  accept: "image/png,image/jpeg",
                                }}
                                onChange={(
                                  event: ChangeEvent<HTMLInputElement>
                                ) => {
                                  const files = (
                                    event.target as HTMLInputElement
                                  ).files;
                                  if (
                                    files &&
                                    files[0].type.includes("image")
                                  ) {
                                    setFileName(files[0].name);
                                    setImage(URL.createObjectURL(files[0]));
                                  } else {
                                    setAlertType(0);
                                    setShowAlert(true);
                                    setAlertMessage(
                                      "This field only accepts images."
                                    );
                                  }
                                }}
                              />
                              <Button
                                component="span"
                                className="upload_image_btn"
                              >
                                <img
                                  src="/static/images/user_placeholder.png"
                                  alt=""
                                />
                                <CameraAltIcon />
                              </Button>
                            </label>
                          </Box>
                        )}
                      </Grid>
                      <Grid item lg={4} md={4} sm={4} xs={12}>
                        <Typography className="custom_label">
                          Full Name
                        </Typography>
                        <TextField
                          hiddenLabel
                          type={"text"}
                          name="title"
                          variant="outlined"
                          fullWidth
                          placeholder="Name"
                        ></TextField>
                      </Grid>
                      <Grid item lg={4} md={4} sm={4} xs={12}>
                        <Typography className="custom_label">Email</Typography>
                        <TextField
                          hiddenLabel
                          type={"email"}
                          name="message"
                          variant="outlined"
                          fullWidth
                          placeholder="Email"
                        ></TextField>
                      </Grid>
                      <Grid item lg={4} md={4} sm={4} xs={12}>
                        <Typography className="custom_label">
                          Phone Number
                        </Typography>
                        <TextField
                          hiddenLabel
                          type={"text"}
                          name="message"
                          variant="outlined"
                          fullWidth
                          placeholder="Phone"
                        ></TextField>
                      </Grid>
                      <Grid item lg={4} md={4} sm={4} xs={12}>
                        <Typography className="custom_label">
                          Select Role
                        </Typography>

                        <FormControl sx={{ width: "100%" }}>
                          <Select
                            className="select_div"
                            fullWidth
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={role}
                            inputProps={{
                              "aria-label": "Without label",
                            }}
                            displayEmpty
                            onChange={handleRoleChange}
                          >
                            <MenuItem value="" disabled>
                              Select
                            </MenuItem>
                            <MenuItem value="role1">Role 1</MenuItem>
                            <MenuItem value="role2">Role 2</MenuItem>
                            <MenuItem value="role3">Role 3</MenuItem>
                          </Select>
                        </FormControl>
                      </Grid>

                      <Grid item xs={12}>
                        <TableContainer className="table_container">
                          <Box className="heading"></Box>
                          <Table
                            sx={{ minWidth: 650 }}
                            aria-label="simple table"
                          >
                            <TableHead>
                              <TableRow>
                                <TableCell className="mn_hdng">
                                  Module
                                </TableCell>
                                <TableCell className="mn_hdng">
                                  Add/Edit
                                </TableCell>
                                <TableCell className="mn_hdng">View</TableCell>
                                <TableCell className="mn_hdng">
                                  Delete
                                </TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {rows.map((row, i) => (
                                <TableRow>
                                  <TableCell className="mn_hdng">
                                    {row.Module}
                                  </TableCell>
                                  <TableCell>
                                    <Checkbox {...label} />
                                  </TableCell>
                                  <TableCell>
                                    <Checkbox {...label} />
                                  </TableCell>
                                  <TableCell>
                                    <Checkbox {...label} />
                                  </TableCell>
                                </TableRow>
                              ))}
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </Grid>
                    </Grid>
                    <div className="form_btn">
                      <Button
                        size="large"
                        type="submit"
                        className="btn btn_primary"
                      >
                        Save
                      </Button>
                    </div>
                  </CardContent>
                </form>
              </CustomTabPanel>
            </Box>
          </Card>
        </div>
      </MainContainer>
    </>
  );
};

export default AddSubAdmin;
