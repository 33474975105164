import { Routes, Route } from "react-router-dom";
import Pages from "./pages";
import UserDetails from "./pages/users/details";
import UsersForm from "./pages/users/form/usersForm";

const Routing = () => {
  return (
    <Routes>
      <Route path="/" element={<Pages.LoginPage />} />
      <Route path="forgotpassword" element={<Pages.ForgotPasswordPage />} />
      <Route path="verifyotp" element={<Pages.VerifyOtp />} />
      <Route path="resetpassword" element={<Pages.ResetPassword />} />
      <Route path="changepassword" element={<Pages.ChangePassword />} />
      <Route path="/dashboard" element={<Pages.DashBoardPage />} />
      <Route path="/profile" element={<Pages.Profile />} />
      <Route path="/manageusers" element={<Pages.ManageUsers />} />
      <Route path="/manageusers/userdetails" element={<UserDetails />} />
      <Route path="/manageusers/usersform" element={<UsersForm />} />
      <Route path="/analytics" element={<Pages.Analytics />} />
      <Route path="/faq" element={<Pages.Faq />} />
      <Route path="/cms" element={<Pages.ManageCms />} />
      <Route
        path="/manage-service-provider"
        element={<Pages.ManageServiceProvider />}
      />
      <Route
        path="/manage-service-provider/details"
        element={<Pages.ServiceProviderDetails />}
      />
      <Route
        path="/manage-service-provider/add"
        element={<Pages.AddServiceProvider />}
      />
      <Route
        path="/manage-service-provider/edit"
        element={<Pages.AddServiceProvider />}
      />
      <Route
        path="/manage-notifications"
        element={<Pages.ManageNotifications />}
      />
      <Route
        path="/manage-notifications/add"
        element={<Pages.AddNotification />}
      />
      <Route
        path="/recieved-notifications"
        element={<Pages.RecievedNotifications />}
      />
      <Route path="/customer-support" element={<Pages.CustomerSupport />} />
      <Route
        path="/customer-support/details"
        element={<Pages.QueryDetails />}
      />

      <Route path="/manage-subAdmin" element={<Pages.ManageSubAdmin />} />
      <Route path="/manage-subAdmin/add" element={<Pages.AddSubAdmin />} />
      <Route path="/manage-categories" element={<Pages.ManageCategories />} />
      <Route path="/manage-categories/add" element={<Pages.AddCategories />} />
      <Route
        path="/manage-categories/details"
        element={<Pages.SubCategories />}
      />

      <Route path="/manage-amenities" element={<Pages.ManageAmenities />} />
      <Route
        path="/manage-listed-properties"
        element={<Pages.ManageListedProperties />}
      />
      <Route
        path="/manage-listed-properties/details"
        element={<Pages.PropertyDetails />}
      />
      <Route
        path="/manage-listed-properties/edit"
        element={<Pages.EditProperties />}
      />
      <Route path="/manage-bookings" element={<Pages.ManageBookings />} />
      <Route
        path="/manage-bookings/details"
        element={<Pages.BookingDetails />}
      />
      <Route path="/manage-amenities/add" element={<Pages.AddAmenities />} />
      <Route path="/manage-community-forum" element={<Pages.ManageReviews />} />
      <Route
        path="/manage-community-forum/details"
        element={<Pages.CommunityDetails />}
      />
      <Route path="/manage-reviews/details" element={<Pages.ReviewDetails />} />
      <Route path="/manage-revenue" element={<Pages.ManageRevenue />} />
      <Route
        path="/manage-revenue/details"
        element={<Pages.RevenueDetails />}
      />
      <Route path="/manage-courses" element={<Pages.ManageCourses />} />
      <Route path="/manage-courses/add" element={<Pages.AddCourse />} />
      <Route path="/manage-courses/details" element={<Pages.CourseDetails />} />
      <Route path="/manage-challenges" element={<Pages.ManageChallenges />} />
      <Route path="/manage-challenges/add" element={<Pages.AddChallenge />} />
      <Route
        path="/manage-challenges/details"
        element={<Pages.ChallengeDetails />}
      />

      <Route
        path="/manage-questionnaire"
        element={<Pages.ManageQuestionnaire />}
      />
      <Route
        path="/manage-questionnaire/add"
        element={<Pages.AddQuestionnaire />}
      />
      <Route
        path="/manage-subscriptions"
        element={<Pages.ManageSubscription />}
      />
      <Route
        path="/manage-subscriptions/add"
        element={<Pages.AddSubscriptionPlan />}
      />
      <Route path="/manage-surveys" element={<Pages.ManageSurveys />} />
      <Route path="/manage-surveys/add" element={<Pages.AddSurvey />} />
      <Route path="/manage-surveys/details" element={<Pages.SurveyDetails />} />
      <Route
        path="/manage-users/details/survey"
        element={<Pages.SurveyDetails />}
      />
      <Route
        path="/manage-users/details/challenge"
        element={<Pages.ChallengeDetails />}
      />
    </Routes>
  );
};

export default Routing;
