import React, { useState } from "react";
import MainContainer from "../../layout/MainContainer";
import VisibilityIcon from "@mui/icons-material/Visibility";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import DeleteIcon from "@mui/icons-material/Delete";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import {
  Card,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
  Box,
  Switch,
  Button,
  Grid,
  FormControl,
  Select,
  MenuItem,
  SelectChangeEvent,
  Slider,
  Typography,
  Rating,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import SearchBar from "../../components/SearchBar";

const ManageCourses = () => {
  const navigate = useNavigate();
  const [category, setCategory] = useState<string>("");
  const [rating, setRating] = useState<string>("");
  const [priceFilter, setPriceFilter] = useState<string>("");
  const [value, setValue] = React.useState<number[]>([20, 37]);

  const handleChange = (event: Event, newValue: number | number[]) => {
    setValue(newValue as number[]);
  };

  const handleProfessionChange = (event: SelectChangeEvent) => {
    setCategory(event.target.value as string);
  };
  const handleRatingChange = (event: SelectChangeEvent) => {
    setRating(event.target.value as string);
  };

  const handlePriceChange = (event: SelectChangeEvent) => {
    setPriceFilter(event.target.value as string);
  };

  function createData(
    name: string,
    userName: string,
    phone: string,
    email: string,
    planName: string,
    planPrice: string,
    postedAds: string,
    earnings: string
  ) {
    return {
      name,
      userName,
      phone,
      email,
      planName,
      planPrice,
      postedAds,
      earnings,
    };
  }
  const label = { inputProps: { "aria-label": "Switch demo" } };
  const rows = [
    createData(
      "Abdul Hussian",
      "loream ipsum",
      "Abdul67",
      "+971056734321",
      "abdulhussian67@gmail.com",
      "Starter",
      "1 Month / 600 SAR",
      "10"
    ),
  ];

  function valuetext(value: number) {
    return `${value}°C`;
  }
  return (
    <MainContainer>
      <div className="main_loyout">
        <div className="dashboard">
          <h1 className="mn_hdng">Manage Courses</h1>
        </div>
        <Card className="cards">
          <Box className="cards_header">
            <SearchBar />
            <Box className="cards_header_right">
              <Button
                className="btn btn_primary"
                onClick={() => navigate("/manage-courses/add")}
              >
                Add Course
              </Button>
            </Box>
          </Box>

          <TableContainer className="table_container">
            <Box className="heading"></Box>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell align="center">S.No</TableCell>
                  <TableCell align="center">Created On</TableCell>
                  <TableCell>Course Name</TableCell>
                  <TableCell>Category</TableCell>
                  <TableCell>Price</TableCell>
                  <TableCell>Level of Course</TableCell>
                  <TableCell>Total Reviews</TableCell>
                  <TableCell>Rating</TableCell>
                  <TableCell>Status</TableCell>
                  <TableCell>Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.map((row, i) => (
                  <TableRow key={row.name}>
                    <TableCell align="center">{i + 1}</TableCell>
                    <TableCell align="center">24/12/2013</TableCell>
                    <TableCell>Staff Pick</TableCell>
                    <TableCell>category</TableCell>
                    <TableCell>1,000</TableCell>
                    <TableCell>Intermediate</TableCell>
                    <TableCell>10</TableCell>

                    <TableCell>
                      <Rating />
                    </TableCell>
                    <TableCell>
                      <Switch {...label} defaultChecked size="small" />
                    </TableCell>
                    <TableCell>
                      <Box className="table_actions">
                        <IconButton
                          onClick={() => navigate("/manage-courses/details")}
                        >
                          <VisibilityIcon />
                        </IconButton>
                        <IconButton
                          onClick={() => navigate("/manage-courses/add")}
                        >
                          <ModeEditIcon />
                        </IconButton>
                        <IconButton>
                          <DeleteIcon />
                        </IconButton>
                      </Box>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Card>
      </div>
    </MainContainer>
  );
};

export default ManageCourses;
