import React, { useCallback, useEffect, useState } from "react";
import MainContainer from "../../layout/MainContainer";
import {
  Box,
  Button,
  Card,
  CardContent,
  FormControl,
  Grid,
  IconButton,
  MenuItem,
  Select,
  SelectChangeEvent,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import { useNavigate } from "react-router-dom";

const AddSubscriptionPlan = () => {
  const navigate = useNavigate();
  const [subscriptionType, setSubscriptionType] = useState("");
  const [enabledFeatures, setEnabledFeatures] = useState<string>("");

  const handleSubscriptionType = (event: SelectChangeEvent) => {
    setSubscriptionType(event.target.value as string);
  };

  const handleFeatureToggle = (position: number) => {
    // Check if the feature is already enabled
    const isEnabled = enabledFeatures.includes(position.toString());

    // Update enabledFeatures based on toggle action
    if (isEnabled) {
      // Remove the feature if already enabled
      setEnabledFeatures((prevFeatures) =>
        prevFeatures
          .split(",")
          .filter((feature) => feature !== position.toString())
          .join(",")
      );
    } else {
      // Add the feature if not enabled
      setEnabledFeatures(
        (prevFeatures) =>
          (prevFeatures ? prevFeatures + "," : "") + position.toString()
      );
    }
  };

  const label = { inputProps: { "aria-label": "Switch demo" } };
  const planFeatures = [
    {
      position: 1,
      name: "Enhanced Budget Tools",
      // isEnabled: enabledFeatures.charAt(0) === "1",
    },
    {
      position: 2,
      name: "Link Bank Accounts",
      // isEnabled: enabledFeatures.charAt(0) === "2",
    },
    {
      position: 3,
      name: "Advanced Security",
      // isEnabled: enabledFeatures.charAt(0) === "3",
    },
    {
      position: 4,
      name: "Track Spending Habits",
      // isEnabled: enabledFeatures.charAt(0) === "4",
    },
    {
      position: 5,
      name: "Graphs and Charts",
      // isEnabled: enabledFeatures.charAt(0) === "5",
    },
    {
      position: 6,
      name: "Savings jar to visually watch the progress",
      // isEnabled: enabledFeatures.charAt(0) === "6",
    },
    {
      position: 7,
      name: "Recommendations and Analytics",
      // isEnabled: enabledFeatures.charAt(0) === "7",
    },
    {
      position: 8,
      name: "Educational Tips",
      // isEnabled: enabledFeatures.charAt(0) === "8",
    },
    {
      position: 9,
      name: "Savings Goals",
      // isEnabled: enabledFeatures.charAt(0) === "9",
    },
    {
      position: 10,
      name: "Round-Up Savings to Achieve Goals",
      // isEnabled: enabledFeatures.charAt(0) === "10",
    },
    {
      position: 11,
      name: "With No Ads",
      // isEnabled: enabledFeatures.charAt(0) === "11",
    },
    {
      position: 12,
      name: "Notifications",
      // isEnabled: enabledFeatures.charAt(0) === "12",
    },
    {
      position: 13,
      name: "Reward Points",
      // isEnabled: enabledFeatures.charAt(0) === "13",
    },
    {
      position: 14,
      name: "Dive deep into budgets",
      // isEnabled: enabledFeatures.charAt(0) === "14",
    },
    {
      position: 15,
      name: "Bill Reminders",
      // isEnabled: enabledFeatures.charAt(0) === "15",
    },
    {
      position: 16,
      name: "Multiplier to Accelerate Goals",
      // isEnabled: enabledFeatures.charAt(0) === "16",
    },
    {
      position: 17,
      name: "Manual ACH transfers between the external accounts",
      // isEnabled: enabledFeatures.charAt(0) === "17",
    },
    {
      position: 18,
      name: "Auto ACH transfers between the external accounts",
      // isEnabled: enabledFeatures.charAt(0) === "18",
    },
  ];

  return (
    <MainContainer>
      <div className="main_loyout">
        <div className="dashboard">
          <h1 className="mn_hdng">Add Subscription</h1>

          <Button
            className="btn btn_primary"
            onClick={() => {
              navigate("/manage-subscriptions");
            }}
          >
            Back
          </Button>
        </div>
        <Card className="cards">
          <form>
            <CardContent sx={{ p: 1 }}>
              <Grid container spacing={2}>
                <Grid item lg={6} md={6} sm={6} xs={12}>
                  <Typography className="custom_label"> Name</Typography>
                  <TextField
                    hiddenLabel
                    type={"text"}
                    name="name"
                    variant="outlined"
                    fullWidth
                    placeholder=" Name"
                    id="name"
                    inputProps={{ maxLength: 30 }}
                    className="text_field"
                    // onBlur={formik.handleBlur}
                    // value={formik.values.name}
                    // onChange={(val) => {
                    //   if (
                    //     val.target.value === " " ||
                    //     val.target.value === "."
                    //   ) {
                    //   } else if (isString(val.target.value)) {
                    //     formik.handleChange(val);
                    //   }
                    // }}
                    // helperText={formik.touched.name && formik.errors.name}
                  />
                </Grid>
                <Grid item lg={6} md={6} sm={6} xs={12}>
                  <Typography className="custom_label">
                    Validity (days)
                  </Typography>
                  <TextField
                    hiddenLabel
                    type={"text"}
                    name="validity"
                    variant="outlined"
                    fullWidth
                    placeholder=" Validity"
                    id="validity"
                    className="text_field"
                    // onBlur={formik.handleBlur}
                    // value={formik.values.validity}
                    // onChange={(val) => {
                    //   if (
                    //     val.target.value === " " ||
                    //     val.target.value === "."
                    //   ) {
                    //   } else if (isNumber(val.target.value)) {
                    //     formik.handleChange(val);
                    //   }
                    // }}
                    // helperText={
                    //   formik.touched.validity && formik.errors.validity
                    // }
                  />
                </Grid>
                <Grid item lg={6} md={6} sm={6} xs={12}>
                  <Typography className="custom_label"> Price</Typography>
                  <TextField
                    hiddenLabel
                    type={"text"}
                    name="price"
                    variant="outlined"
                    fullWidth
                    placeholder=" Price"
                    id="price"
                    className="text_field"
                    inputProps={{ maxLength: 15 }}
                    // onBlur={formik.handleBlur}
                    // value={formik.values.price}
                    // onChange={(event) => {
                    //   const inputValue = event.target.value;
                    //   // Validate input: allow digits, up to one decimal point, and ensure no more than two decimal places
                    //   const regex = /^\d*\.?\d{0,2}$/;
                    //   if (regex.test(inputValue)) {
                    //     formik.handleChange(event);
                    //   }
                    // }}
                    // helperText={formik.touched.price && formik.errors.price}
                  />
                </Grid>
                <Grid item lg={6} md={6} sm={6} xs={12}>
                  <Typography className="custom_label">
                    Subscription Type
                  </Typography>
                  <FormControl sx={{ width: "100%" }}>
                    <Select
                      className="select_div"
                      fullWidth
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={subscriptionType}
                      inputProps={{
                        "aria-label": "Without label",
                      }}
                      displayEmpty
                      onChange={handleSubscriptionType}
                    >
                      <MenuItem value="" disabled>
                        Select
                      </MenuItem>
                      <MenuItem value="monthly">Monthly</MenuItem>
                      <MenuItem value="yearly">Yearly</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item lg={6} md={6} sm={6} xs={12}>
                  <Typography className="custom_label">Description</Typography>
                  <TextField
                    className="text_field"
                    hiddenLabel
                    type="text"
                    placeholder="Description"
                    fullWidth
                    multiline
                    minRows={4}
                    maxRows={4}
                    name="description"
                    variant="outlined"
                    id="description"
                    // onChange={(val) => {
                    //   if (
                    //     val.target.value === " " ||
                    //     val.target.value === "."
                    //   ) {
                    //   } else {
                    //     formik.handleChange(val);
                    //   }
                    // }}
                    // onBlur={formik.handleBlur}
                    // value={formik.values.description}
                    // helperText={
                    //   formik.touched.description && formik.errors.description
                    // }
                  />
                </Grid>

                {/* <Grid item xs={12}>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <Typography
                      component="h2"
                      sx={{
                        fontSize: "20px",
                        color: "#051140",
                        fontWeight: 700,
                      }}
                    >
                      Plan Features
                    </Typography>
                  </Box>
                </Grid>

                <Grid item xs={12}>
                  <TableContainer
                    className="table_container"
                    sx={{ margin: 0 }}
                  >
                    <Box className="heading"></Box>
                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                      <TableHead>
                        <TableRow>
                          <TableCell align="center">S.No</TableCell>
                          <TableCell>Feature</TableCell>
                          <TableCell> Status</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {planFeatures.map((row, i) => (
                          <TableRow key={i}>
                            <TableCell align="center">
                              {row?.position || ""}
                            </TableCell>
                            <TableCell>{row.name || ""}</TableCell>
                            <TableCell>
                              <Switch
                                {...label}
                                checked={enabledFeatures.includes(
                                  row.position.toString()
                                )}
                                onChange={() =>
                                  handleFeatureToggle(row.position)
                                }
                                size="small"
                              />
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Grid> */}
              </Grid>
              <div className="form_btn">
                <Button size="large" type="submit" className="btn btn_primary">
                  Save
                </Button>
              </div>
            </CardContent>
          </form>
        </Card>
      </div>
    </MainContainer>
  );
};

export default AddSubscriptionPlan;
