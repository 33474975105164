import React from "react";
import MainContainer from "../../layout/MainContainer";
import {
  Button,
  Card,
  IconButton,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import Box from "@mui/material/Box";
import SearchBar from "../../components/SearchBar";
import { useNavigate } from "react-router-dom";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import DeleteIcon from "@mui/icons-material/Delete";

const ManageSubAdmin = () => {
  const navigate = useNavigate();

  function createData(Name: string, Email: string, Phone: string) {
    return { Name, Email, Phone };
  }
  const label = { inputProps: { "aria-label": "Switch demo" } };
  const rows = [createData("Sub Admin", "subadmin@gmail.com", "+971056734321")];

  return (
    <MainContainer>
      <div className="main_loyout">
        <div className="dashboard">
          <h1 className="mn_hdng"> Manage Sub-Admin</h1>
        </div>
        <Card className="cards">
          <Box className="cards_header">
            <SearchBar />
            <Box className="cards_header_right">
              <Button
                className="btn btn_primary"
                onClick={() => navigate("/manage-subAdmin/add")}
              >
                Add Sub-Admin
              </Button>
            </Box>
          </Box>
          <TableContainer className="table_container">
            <Box className="heading"></Box>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell align="center">S.No</TableCell>
                  <TableCell>Name</TableCell>
                  <TableCell>Role</TableCell>
                  <TableCell>Email</TableCell>
                  <TableCell>Phone</TableCell>
                  <TableCell>Status</TableCell>
                  <TableCell>Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.map((row, i) => (
                  <TableRow>
                    <TableCell align="center">{i + 1}</TableCell>
                    <TableCell>{row.Name}</TableCell>
                    <TableCell>Role name</TableCell>

                    <TableCell>{row.Email}</TableCell>
                    <TableCell>{row.Phone}</TableCell>
                    <TableCell>
                      <Switch {...label} defaultChecked size="small" />
                    </TableCell>
                    <TableCell>
                      <Box className="table_actions">
                        <IconButton
                          onClick={() => navigate("/manage-subAdmin/add")}
                        >
                          <ModeEditIcon />
                        </IconButton>
                        <IconButton>
                          <DeleteIcon />
                        </IconButton>
                      </Box>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Card>
      </div>
    </MainContainer>
  );
};

export default ManageSubAdmin;
