import React from "react";
import MainContainer from "../../layout/MainContainer";
import {
  Button,
  Card,
  CardContent,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import { useNavigate } from "react-router-dom";

const AddSurvey = () => {
  const navigate = useNavigate();
  return (
    <MainContainer>
      <div className="main_loyout">
        <div className="dashboard">
          <h1 className="mn_hdng">Add Survey</h1>
          <Button
            className="btn btn_primary"
            onClick={() => {
              navigate("/manage-surveys");
            }}
          >
            Back
          </Button>
        </div>
        <Card className="cards">
          <form>
            <CardContent sx={{ p: 1 }}>
              <Grid container spacing={2}>
                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <Typography className="custom_label">
                    Add a Question
                  </Typography>
                  <TextField
                    hiddenLabel
                    type={"text"}
                    name="firstName"
                    variant="outlined"
                    fullWidth
                    placeholder=" Add a Question"
                  />
                </Grid>

                <Grid item lg={6} md={6} sm={6} xs={12}>
                  <Typography className="custom_label">Option 1</Typography>
                  <TextField
                    hiddenLabel
                    type="text"
                    name="email"
                    variant="outlined"
                    fullWidth
                    placeholder="Option 1"
                  ></TextField>
                </Grid>
                <Grid item lg={6} md={6} sm={6} xs={12}>
                  <Typography className="custom_label">Option 2</Typography>
                  <TextField
                    hiddenLabel
                    type="text"
                    name="email"
                    variant="outlined"
                    fullWidth
                    placeholder="Option 2"
                  ></TextField>
                </Grid>
                <Grid item lg={6} md={6} sm={6} xs={12}>
                  <Typography className="custom_label">Option 3</Typography>
                  <TextField
                    hiddenLabel
                    type="text"
                    name="email"
                    variant="outlined"
                    fullWidth
                    placeholder="Option 3"
                  ></TextField>
                </Grid>
                <Grid item lg={6} md={6} sm={6} xs={12}>
                  <Typography className="custom_label">Option 4</Typography>
                  <TextField
                    hiddenLabel
                    type="text"
                    name="email"
                    variant="outlined"
                    fullWidth
                    placeholder="Option 4"
                  ></TextField>
                </Grid>
                <Grid item lg={6} md={6} sm={6} xs={12}>
                  <Typography className="custom_label">
                    Correct Answer
                  </Typography>
                  <TextField
                    hiddenLabel
                    type="text"
                    name="email"
                    variant="outlined"
                    fullWidth
                    placeholder="Correct Answer"
                  ></TextField>
                </Grid>

                {/* <Grid item lg={6} md={6} sm={6} xs={12}>
                    <Typography className="custom_label">Password</Typography>
                    <TextField
                      hiddenLabel
                      type={"text"}
                      name="password"
                      variant="outlined"
                      fullWidth
                      placeholder="Password"
                    />
                  </Grid> */}
              </Grid>
              <div className="form_btn">
                <Button size="large" type="submit" className="btn btn_primary">
                  Save
                </Button>
              </div>
            </CardContent>
          </form>
        </Card>
      </div>
    </MainContainer>
  );
};

export default AddSurvey;
