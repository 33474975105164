import {
  Box,
  Button,
  Card,
  CardContent,
  CardMedia,
  FormControl,
  Grid,
  Input,
  MenuItem,
  Select,
  SelectChangeEvent,
  Tab,
  Tabs,
  TextField,
  Typography,
} from "@mui/material";

import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/bootstrap.css";
import { ChangeEvent, useState } from "react";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import CancelIcon from "@mui/icons-material/Cancel";

import { useNavigate } from "react-router-dom";
import AddIcon from "@mui/icons-material/Add";
import MainContainer from "../../layout/MainContainer";
import { Quiz } from "../../features";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const AddCourse = () => {
  const [curriculum, setCurriculum] = useState<string[]>([]);
  const [alertMessage, setAlertMessage] = useState("");
  const [showAlert, setShowAlert] = useState(false);
  const [alertType, setAlertType] = useState(0);
  const [age, setAge] = useState("");

  const [image, setImage] = useState<string>("");
  const [video, setVideo] = useState<string>("");
  const [fileName, setFileName] = useState<string>("");
  const [category, setCategory] = useState<string>("");
  const [pricing, setPricing] = useState<string>("");
  const [certificate, setCertificate] = useState<string>("");
  const [level, setLevel] = useState<string>("");
  const [value, setValue] = useState<number>(0);
  const [file, setFile] = useState<string | null>(null);

  const handleFileChange = async (event: ChangeEvent<HTMLInputElement>) => {
    const files = (event.target as HTMLInputElement).files;
    if (files && files[0]) {
      const reader = new FileReader();

      reader.onload = () => {
        const fileDataURL = reader.result as string;
        setFile(fileDataURL);
      };

      reader.readAsDataURL(files[0]);
    }
  };

  const handleCancel = () => {
    setFile(null);
  };

  const isImage = file && file.startsWith("data:image");
  const isVideo = file && file.startsWith("data:video/mp4");
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const navigate = useNavigate();

  const handleClose = () => {
    setShowAlert(false);
  };
  const handleImageUpload = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files[0]) {
      console.log(event.target.files, "event.target.files");

      setImage(URL.createObjectURL(event.target.files[0]));
    }
  };

  const handleCategoryChange = (event: SelectChangeEvent) => {
    setCategory(event.target.value as string);
  };
  const handlePricingChange = (event: SelectChangeEvent) => {
    setPricing(event.target.value as string);
  };
  const handleCertificateChange = (event: SelectChangeEvent) => {
    setCertificate(event.target.value as string);
  };
  const handleLevelChange = (event: SelectChangeEvent) => {
    setLevel(event.target.value as string);
  };

  return (
    <>
      <MainContainer>
        <div className="main_loyout">
          <div className="dashboard">
            <h1 className="mn_hdng">Add Course</h1>
            <Button
              className="btn btn_primary"
              onClick={() => {
                navigate("/manage-courses");
              }}
            >
              Back
            </Button>
          </div>
          <Card className="cards">
            <Box className="custom_tabs">
              <Box className="flx_sc">
                <Tabs
                  value={value}
                  onChange={handleChange}
                  variant="scrollable"
                  scrollButtons="auto"
                  aria-label="basic tabs example"
                  className="custom_tabs_links"
                >
                  <Tab label="Add Course Details" {...a11yProps(0)} />
                  <Tab label="Add Quiz" {...a11yProps(1)} />
                </Tabs>
              </Box>
              <CustomTabPanel value={value} index={0}>
                <form>
                  <CardContent sx={{ p: 1 }}>
                    <Grid container spacing={2}>
                      <Grid item lg={4} md={4} sm={4} xs={12}>
                        <Typography className="custom_label">
                          Course Name
                        </Typography>
                        <TextField
                          hiddenLabel
                          type={"text"}
                          name="firstName"
                          variant="outlined"
                          fullWidth
                          placeholder="Course Name"
                        ></TextField>
                      </Grid>
                      <Grid item lg={4} md={4} sm={6} xs={12}>
                        <Typography className="custom_label">
                          Select Category
                        </Typography>
                        <FormControl sx={{ width: "100%" }}>
                          <Select
                            className="select_div"
                            fullWidth
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={category}
                            inputProps={{
                              "aria-label": "Without label",
                            }}
                            displayEmpty
                            onChange={handleCategoryChange}
                          >
                            <MenuItem value="" disabled>
                              Select
                            </MenuItem>
                            <MenuItem value="cat1">Category 1</MenuItem>
                            <MenuItem value="cat2">Category 2</MenuItem>
                            <MenuItem value="cat3">Category 3</MenuItem>
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid item lg={4} md={4} sm={6} xs={12}>
                        <Typography className="custom_label">
                          Pricing type
                        </Typography>
                        <FormControl sx={{ width: "100%" }}>
                          <Select
                            className="select_div"
                            fullWidth
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={pricing}
                            inputProps={{
                              "aria-label": "Without label",
                            }}
                            displayEmpty
                            onChange={handlePricingChange}
                          >
                            <MenuItem value="" disabled>
                              Select
                            </MenuItem>
                            <MenuItem value="free">Free</MenuItem>
                            <MenuItem value="paid">Paid</MenuItem>
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid item lg={4} md={4} sm={6} xs={12}>
                        <Typography className="custom_label">
                          Certificate
                        </Typography>
                        <FormControl sx={{ width: "100%" }}>
                          <Select
                            className="select_div"
                            fullWidth
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={certificate}
                            inputProps={{
                              "aria-label": "Without label",
                            }}
                            displayEmpty
                            onChange={handleCertificateChange}
                          >
                            <MenuItem value="" disabled>
                              Select
                            </MenuItem>
                            <MenuItem value="yes">Yes</MenuItem>
                            <MenuItem value="no">No</MenuItem>
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid item lg={4} md={4} sm={4} xs={12}>
                        <Typography className="custom_label">Price</Typography>
                        <TextField
                          hiddenLabel
                          type={"text"}
                          name="firstName"
                          variant="outlined"
                          fullWidth
                          placeholder="Price"
                        ></TextField>
                      </Grid>
                      <Grid item lg={4} md={4} sm={4} xs={12}>
                        <Typography className="custom_label">
                          Level of Course
                        </Typography>
                        <FormControl sx={{ width: "100%" }}>
                          <Select
                            className="select_div"
                            fullWidth
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={level}
                            inputProps={{
                              "aria-label": "Without label",
                            }}
                            displayEmpty
                            onChange={handleLevelChange}
                          >
                            <MenuItem value="" disabled>
                              Select
                            </MenuItem>

                            <MenuItem value="beginner">Beginner</MenuItem>
                            <MenuItem value="intermediate">
                              Intermediate
                            </MenuItem>
                            <MenuItem value="expert">Expert</MenuItem>
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid item lg={4} md={4} sm={4} xs={12}>
                        <Typography className="custom_label">
                          Publisher/Author name
                        </Typography>
                        <TextField
                          hiddenLabel
                          type={"text"}
                          name="firstName"
                          variant="outlined"
                          fullWidth
                          placeholder="Publisher/Author name"
                        ></TextField>
                      </Grid>

                      <Grid item lg={4} md={4} sm={4} xs={12}>
                        <Typography className="custom_label">
                          Validity (months)
                        </Typography>
                        <TextField
                          hiddenLabel
                          type={"text"}
                          name="firstName"
                          variant="outlined"
                          fullWidth
                          placeholder="Validity"
                        />
                      </Grid>

                      <Grid item lg={6} md={6} sm={6} xs={12}>
                        <Typography className="custom_label">
                          Curriculum
                        </Typography>
                        <TextField
                          className="text_field"
                          hiddenLabel
                          type="text"
                          placeholder="Curriculum"
                          fullWidth
                          multiline
                          minRows={4}
                          maxRows={4}
                          name="description"
                          variant="outlined"
                          id="description"
                        />
                      </Grid>
                      <Grid item lg={6} md={6} sm={6} xs={12}>
                        <Typography className="custom_label">
                          Description
                        </Typography>
                        <TextField
                          className="text_field"
                          hiddenLabel
                          type="text"
                          placeholder="Full Description"
                          fullWidth
                          multiline
                          minRows={4}
                          maxRows={4}
                          name="description"
                          variant="outlined"
                          id="description"
                        />
                      </Grid>
                      {/* <Grid container spacing={2}> */}
                      <Grid item lg={6} md={6} sm={12} xs={12}>
                        <Typography className="custom_label">
                          Upload Media
                        </Typography>
                        {file ? (
                          <div className="upload_image_preview2">
                            {isImage ? (
                              <CardMedia
                                component="img"
                                image={file}
                                alt="photo"
                              />
                            ) : isVideo ? (
                              <video
                                autoPlay
                                muted
                                loop
                                playsInline
                                className="video_dv"
                                preload="yes"
                              >
                                <source src={file} type="video/mp4" />
                              </video>
                            ) : null}
                            <CancelIcon onClick={handleCancel} />
                          </div>
                        ) : (
                          <Box className="upload_image_bnr">
                            <label htmlFor="icon-button-file">
                              <Input
                                sx={{ display: "none" }}
                                id="icon-button-file"
                                type="file"
                                inputProps={{
                                  accept: "image/*,video/*",
                                }}
                                onChange={handleFileChange}
                              />
                              <Button
                                component="span"
                                className="upload_image_btn"
                              >
                                <AddIcon />
                              </Button>
                            </label>
                          </Box>
                        )}
                      </Grid>
                      <Grid item lg={6} md={6} sm={12} xs={12} />

                      {/* <Grid item lg={6} md={6} sm={12} xs={12}>
                        <Typography className="custom_label">
                          Cover Image
                        </Typography>
                        {image ? (
                          <div className="upload_image_preview2">
                            <CardMedia
                              component="img"
                              image={image}
                              alt="photo"
                            />

                            <CancelIcon
                              onClick={() => {
                                setImage("");
                              }}
                            />
                          </div>
                        ) : (
                          <Box className="upload_image_bnr">
                            <label htmlFor="icon-button-file">
                              <Input
                                sx={{ display: "none" }}
                                id="icon-button-file"
                                type="file"
                                inputProps={{
                                  accept: "image/png,image/jpeg",
                                }}
                                onChange={(
                                  event: ChangeEvent<HTMLInputElement>
                                ) => {
                                  const files = (
                                    event.target as HTMLInputElement
                                  ).files;
                                  if (
                                    files &&
                                    files[0].type.includes("image")
                                  ) {
                                    setImage(URL.createObjectURL(files[0]));
                                  }
                                }}
                                //   onChange={handleImageUpload}
                              />
                              <Button
                                component="span"
                                className="upload_image_btn"
                              >
                                {/* <img
                                src={
                                  image
                                    ? image
                                    : "/static/images/user_placeholder.png"
                                }
                                alt=""
                              /> */}
                      {/* <AddIcon />
                              </Button>
                            </label>
                          </Box>
                        )}
                      </Grid> */}

                      {/* <Grid item lg={6} md={6} sm={12} xs={12}>
                        <Typography className="custom_label">
                          Upload Your Videos
                        </Typography>
                        {video ? (
                          <div className="upload_image_preview2">
                            {/* <CardMedia component="img" image={video} alt="photo" /> */}
                      {/* <video
                              autoPlay
                              muted
                              loop
                              playsInline
                              className="video_dv"
                              preload="yes"
                            >
                              <source src={video} type="video/mp4" />
                            </video>
                            <CancelIcon
                              onClick={() => {
                                setVideo("");
                              }}
                            />
                          </div>
                        ) : (
                          <Box className="upload_image_bnr">
                            <label htmlFor="icon-button-file-video">
                              <Input
                                sx={{ display: "none" }}
                                id="icon-button-file-video"
                                type="file"
                                inputProps={{
                                  accept: "video/*",
                                }}
                                onChange={(
                                  event: ChangeEvent<HTMLInputElement>
                                ) => {
                                  const files = (
                                    event.target as HTMLInputElement
                                  ).files;
                                  if (
                                    files &&
                                    files[0].type.includes("video/")
                                  ) {
                                    setVideo(URL.createObjectURL(files[0]));
                                  }
                                }} */}
                      {/* //   onChange={handleImageUpload} */}
                      {/* />
                              <Button
                                component="span"
                                className="upload_image_btn"
                              > */}
                      {/* <img
                                src={
                                  image
                                    ? image
                                    : "/static/images/user_placeholder.png"
                                }
                                alt=""
                              /> */}
                      {/* <AddIcon />
                              </Button>
                            </label>
                          </Box>
                        )}
                      </Grid> */}
                    </Grid>
                    <div className="form_btn">
                      <Button
                        size="large"
                        type="submit"
                        className="btn btn_primary"
                        onClick={() => setValue(1)}
                      >
                        Next
                      </Button>
                    </div>
                  </CardContent>
                </form>
              </CustomTabPanel>
              <CustomTabPanel value={value} index={1}>
                <Quiz />
                <div className="form_btn">
                  <Button
                    size="large"
                    type="submit"
                    className="btn btn_primary"
                  >
                    Save
                  </Button>
                </div>
              </CustomTabPanel>
            </Box>
          </Card>
        </div>
      </MainContainer>
    </>
  );
};

export default AddCourse;
