import React, { useState } from "react";
import MainContainer from "../../layout/MainContainer";
import {
  Button,
  Card,
  IconButton,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";

import Box from "@mui/material/Box";
import SearchBar from "../../components/SearchBar";
import { useNavigate } from "react-router-dom";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import DeleteIcon from "@mui/icons-material/Delete";
import VisibilityIcon from "@mui/icons-material/Visibility";

const ManageChallenges = () => {
  const navigate = useNavigate();

  function createData(Name: string) {
    return { Name };
  }
  const label = { inputProps: { "aria-label": "Switch demo" } };
  const rows = [createData("Villas"), createData("Resorts")];

  return (
    <MainContainer>
      <div className="main_loyout">
        <div className="dashboard">
          <h1 className="mn_hdng">Manage Challenges</h1>
        </div>
        <Card className="cards">
          <Box className="custom_tabs">
            <Box className="cards_header">
              <SearchBar />
              <Box className="cards_header_right">
                <Button
                  className="btn btn_primary"
                  onClick={() => navigate("/manage-challenges/add")}
                >
                  Add Challenge
                </Button>
              </Box>
            </Box>
            <TableContainer className="table_container">
              <Box className="heading"></Box>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell align="center">S.No</TableCell>
                    <TableCell align="center">Created On</TableCell>
                    {/* <TableCell>Image</TableCell> */}
                    <TableCell>Title</TableCell>
                    <TableCell>Description</TableCell>
                    <TableCell>Action</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rows.map((row, i) => (
                    <TableRow>
                      <TableCell align="center">{i + 1}</TableCell>
                      <TableCell align="center">24/12/2013</TableCell>
                      {/* <TableCell>
                        <figure className="user_img">
                          <img
                            src="/static/images/user_placeholder.png"
                            alt=""
                          />
                        </figure>
                      </TableCell> */}
                      <TableCell>Challenge Title</TableCell>
                      <TableCell>Lorem Ipsum..</TableCell>
                      <TableCell>
                        <Box className="table_actions">
                          <IconButton
                            onClick={() =>
                              navigate("/manage-challenges/details")
                            }
                          >
                            <VisibilityIcon />
                          </IconButton>
                          <IconButton
                            onClick={() => navigate("/manage-challenges/add")}
                          >
                            <ModeEditIcon />
                          </IconButton>
                          <IconButton>
                            <DeleteIcon />
                          </IconButton>
                        </Box>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </Card>
      </div>
    </MainContainer>
  );
};

export default ManageChallenges;
