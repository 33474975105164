import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  IconButton,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import React from "react";
import MainContainer from "../../layout/MainContainer";
import { useNavigate } from "react-router-dom";

const ChallengeDetails = () => {
  const navigate = useNavigate();
  const label = { inputProps: { "aria-label": "Switch demo" } };
  return (
    <MainContainer>
      <div className="main_loyout">
        <div className="dashboard">
          <h1 className="mn_hdng">Challenge Details</h1>
          <Button
            className="btn btn_primary"
            onClick={() => {
              navigate("/manageUsers/userdetails");
            }}
          >
            Back
          </Button>
        </div>
        <Card className="cards">
          <CardContent sx={{ p: 1 }}>
            <Grid container spacing={2} className="view_box">
              <Grid item xs={10} className="view_box_list">
                <Grid container spacing={3}>
                  <Grid item lg={6} md={6} sm={6} xs={12}>
                    <Box>
                      <Typography component="h5"> Title</Typography>
                      <Typography component="p">Challenge Title</Typography>
                    </Box>
                  </Grid>
                  <Grid item lg={6} md={6} sm={6} xs={12} />
                  <Grid item lg={12} md={12} sm={12} xs={12}>
                    <Box>
                      <Typography component="h5">Description</Typography>
                      <Typography component="p">
                        Lorem ipsum dolor sit amet consectetur, adipisicing elit
                        dolor sit amet consectetur. Lorem ipsum dolor sit amet
                        consectetur, adipisicing elit dolor sit amet
                        consectetur.
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item lg={6} md={6} sm={6} xs={12}>
                    <Box>
                      <Typography component="h5">Challenge Question</Typography>
                      <Typography component="p">
                        What is UI/UX design?
                      </Typography>
                    </Box>
                  </Grid>
                  {/* <Grid item lg={6} md={6} sm={6} xs={12}>
                    <Box>
                      <Typography component="h5">User Answer</Typography>
                      <Typography component="p">
                        User Interface and User Experience
                      </Typography>
                    </Box>
                  </Grid> */}
                  <Grid item lg={6} md={6} sm={6} xs={12}>
                    <Box>
                      <Typography component="h5">Challenge Points</Typography>
                      <Typography component="p">10</Typography>
                    </Box>
                  </Grid>
                  <Grid item lg={6} md={6} sm={6} xs={12}>
                    <Box>
                      <Typography component="h5">Features</Typography>
                      <Typography component="p">Feature 1</Typography>
                      <Typography component="p">Feature 2</Typography>
                      <Typography component="p">Feature 3</Typography>
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
        <Card sx={{ mt: 4 }} className="cards">
          <Box className="custom_tabs">
            <h2 className="mn_hdng">User Response</h2>
            <TableContainer className="table_container">
              <Box className="heading"></Box>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell align="center">S.No</TableCell>
                    <TableCell align="center">Date</TableCell>

                    <TableCell>User name</TableCell>
                    <TableCell>User Response</TableCell>
                    <TableCell align="center">Admin Approval</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell align="center">{1}</TableCell>
                    <TableCell align="center">24/12/1999</TableCell>

                    <TableCell>John</TableCell>
                    <TableCell>Answer</TableCell>
                    <TableCell align="center">
                      <Switch {...label} defaultChecked size="small" />
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </Card>
      </div>
    </MainContainer>
  );
};

export default ChallengeDetails;
