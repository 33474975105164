import {
  Box,
  Button,
  Card,
  CardContent,
  CardMedia,
  Grid,
  TextField,
  Typography,
  Input,
  FormControl,
  Select,
  MenuItem,
  SelectChangeEvent,
} from "@mui/material";
import MainContainer from "../../layout/MainContainer";
import { useLocation, useNavigate } from "react-router-dom";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import CancelIcon from "@mui/icons-material/Cancel";
import { ChangeEvent, useState } from "react";
import Multiselect from "multiselect-react-dropdown";
import AddIcon from "@mui/icons-material/Add";
import EditText from "../../components/EditText";

interface Option {
  id: number;
  value: string;
}

const AddChallenge = () => {
  const navigate = useNavigate();
  const [features, setFeatures] = useState("");

  const [category, setCategory] = useState<string>("");
  const [selectedOptions, setSelectedOptions] = useState<Option[]>([]);
  const [image, setImage] = useState<string>("");

  const [file, setFile] = useState<string | null>(null);

  const handleFileChange = async (event: ChangeEvent<HTMLInputElement>) => {
    const files = (event.target as HTMLInputElement).files;
    if (files && files[0]) {
      const reader = new FileReader();

      reader.onload = () => {
        const fileDataURL = reader.result as string;
        setFile(fileDataURL);
      };

      reader.readAsDataURL(files[0]);
    }
  };

  const handleCancel = () => {
    setFile(null);
  };

  const isImage = file && file.startsWith("data:image");
  const isVideo = file && file.startsWith("data:video/mp4");

  const options: Option[] = [
    { id: 1, value: "Option 1" },
    { id: 2, value: "Option 2" },
    { id: 3, value: "Option 3" },
  ];
  const onSelect = (selectedList: Option[], selectedItem: Option) => {
    setSelectedOptions(selectedList);
  };
  const onRemove = (selectedList: Option[], removedItem: Option) => {
    setSelectedOptions(selectedList);
  };

  const handleCategoryChange = (event: SelectChangeEvent) => {
    setCategory(event.target.value as string);
  };
  return (
    <>
      <MainContainer>
        <div className="main_loyout">
          <div className="dashboard">
            <h1>Add Challenge</h1>
            <Button
              className="btn btn_primary"
              onClick={() => {
                navigate("/manage-challenges");
              }}
            >
              Back
            </Button>
          </div>
          <Card className="cards">
            <form>
              <CardContent sx={{ p: 1 }}>
                <Grid container spacing={2}>
                  <Grid item lg={6} md={6} sm={12} xs={12}>
                    <Typography className="custom_label">
                      Upload Media
                    </Typography>
                    {file ? (
                      <div className="upload_image_preview2">
                        {isImage ? (
                          <CardMedia component="img" image={file} alt="photo" />
                        ) : isVideo ? (
                          <video
                            autoPlay
                            muted
                            loop
                            playsInline
                            className="video_dv"
                            preload="yes"
                          >
                            <source src={file} type="video/mp4" />
                          </video>
                        ) : null}
                        <CancelIcon onClick={handleCancel} />
                      </div>
                    ) : (
                      <Box className="upload_image_bnr">
                        <label htmlFor="icon-button-file">
                          <Input
                            sx={{ display: "none" }}
                            id="icon-button-file"
                            type="file"
                            inputProps={{
                              accept: "image/*,video/*",
                            }}
                            onChange={handleFileChange}
                          />
                          <Button component="span" className="upload_image_btn">
                            <AddIcon />
                          </Button>
                        </label>
                      </Box>
                    )}
                  </Grid>
                  <Grid item lg={6} md={6} sm={12} xs={12}>
                    <Typography className="custom_label">
                      Question Image
                    </Typography>
                    {image ? (
                      <div className="upload_image_preview2">
                        <CardMedia component="img" image={image} alt="photo" />
                        <CancelIcon
                          onClick={() => {
                            setImage("");
                          }}
                        />
                      </div>
                    ) : (
                      <Box className="upload_image_bnr">
                        <label htmlFor="icon-button-image">
                          <Input
                            sx={{ display: "none" }}
                            id="icon-button-image"
                            type="file"
                            inputProps={{
                              accept: "image/png,image/jpeg",
                            }}
                            onChange={(
                              event: ChangeEvent<HTMLInputElement>
                            ) => {
                              const files = (event.target as HTMLInputElement)
                                .files;
                              if (files && files[0].type.includes("image")) {
                                setImage(URL.createObjectURL(files[0]));
                              }
                            }}
                          />
                          <Button component="span" className="upload_image_btn">
                            <AddIcon />
                          </Button>
                        </label>
                      </Box>
                    )}
                  </Grid>

                  {/* <Grid item lg={6} md={6} sm={12} xs={12}>
                    <Typography className="custom_label">
                      Upload Video
                    </Typography>
                    {video ? (
                      <div className="upload_image_preview2">
                       
                        <video
                          autoPlay
                          muted
                          loop
                          playsInline
                          className="video_dv"
                          preload="yes"
                        >
                          <source src={video} type="video/mp4" />
                        </video>
                        <CancelIcon
                          onClick={() => {
                            setVideo("");
                          }}
                        />
                      </div>
                    ) : (
                      <Box className="upload_image_bnr">
                        <label htmlFor="icon-button-file-video">
                          <Input
                            sx={{ display: "none" }}
                            id="icon-button-file-video"
                            type="file"
                            inputProps={{
                              accept: "video/*",
                            }}
                            onChange={(
                              event: ChangeEvent<HTMLInputElement>
                            ) => {
                              const files = (event.target as HTMLInputElement)
                                .files;
                              if (files && files[0].type.includes("video/")) {
                                setVideo(URL.createObjectURL(files[0]));
                              }
                            }}
                   
                          />
                          <Button component="span" className="upload_image_btn">
                           
                            <AddIcon />
                          </Button>
                        </label>
                      </Box>
                    )}
                  </Grid> */}

                  <Grid item lg={6} md={6} sm={6} xs={12}>
                    <Typography className="custom_label">Title</Typography>
                    <TextField
                      hiddenLabel
                      type={"text"}
                      name="title"
                      variant="outlined"
                      fullWidth
                      placeholder="Title"
                    />
                  </Grid>
                  <Grid item lg={6} md={6} sm={6} xs={12}>
                    <Typography className="custom_label">Tag</Typography>
                    <Multiselect
                      className="multi_select"
                      options={options}
                      displayValue="value"
                      selectedValues={selectedOptions}
                      onSelect={onSelect}
                      onRemove={onRemove}
                    />
                  </Grid>
                  <Grid item lg={6} md={6} sm={6} xs={12}>
                    <Typography className="custom_label">
                      Challenge Points
                    </Typography>
                    <TextField
                      hiddenLabel
                      type={"text"}
                      name="title"
                      variant="outlined"
                      fullWidth
                      placeholder="Challenge Points"
                    />
                  </Grid>
                  <Grid item lg={6} md={6} sm={6} xs={12}>
                    <Typography className="custom_label">
                      Add Question
                    </Typography>
                    <TextField
                      hiddenLabel
                      type={"text"}
                      name="title"
                      variant="outlined"
                      fullWidth
                      placeholder=" Add Question"
                    />
                  </Grid>
                  {/* <Grid item lg={6} md={6} sm={6} xs={12} /> */}
                  <Grid item lg={6} md={6} sm={6} xs={12}>
                    <Typography className="custom_label">
                      Description
                    </Typography>
                    <TextField
                      className="text_field"
                      hiddenLabel
                      type="text"
                      placeholder="Description"
                      fullWidth
                      multiline
                      minRows={4}
                      maxRows={4}
                      name="description"
                      variant="outlined"
                      id="description"
                    />
                  </Grid>
                  <Grid item lg={6} md={6} sm={6} xs={12}>
                    <Typography className="custom_label">Features</Typography>
                    <EditText content={features} setContent={setFeatures} />
                  </Grid>
                </Grid>
                <div className="form_btn">
                  <Button
                    size="large"
                    type="submit"
                    className="btn btn_primary"
                  >
                    Save
                  </Button>
                </div>
              </CardContent>
            </form>
          </Card>
        </div>
      </MainContainer>
    </>
  );
};

export default AddChallenge;
