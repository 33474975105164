import React from "react";
import MainContainer from "../../layout/MainContainer";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";

const CourseDetails = () => {
  const navigate = useNavigate();
  return (
    <MainContainer>
      <div className="main_loyout">
        <div className="dashboard">
          <h1 className="mn_hdng">Course Details</h1>
          <Button
            className="btn btn_primary"
            onClick={() => {
              navigate("/manage-courses");
            }}
          >
            Back
          </Button>
        </div>
        <Card className="cards">
          <CardContent sx={{ p: 1 }}>
            <Grid item xs={10} className="view_box_list">
              <Grid container spacing={3}>
                <Grid item lg={3} md={3} sm={6} xs={12}>
                  <Box>
                    <Typography component="h5">Course Name</Typography>
                    <Typography component="p">Course Name</Typography>
                  </Box>
                </Grid>
                <Grid item lg={3} md={3} sm={6} xs={12}>
                  <Box>
                    <Typography component="h5">No. of Enrolments</Typography>
                    <Typography component="p">10</Typography>
                  </Box>
                </Grid>
                <Grid item lg={3} md={3} sm={6} xs={12}>
                  <Box>
                    <Typography component="h5">Category</Typography>
                    <Typography component="p">Category name</Typography>
                  </Box>
                </Grid>

                <Grid item lg={3} md={3} sm={6} xs={12}>
                  <Box>
                    <Typography component="h5">Pricing Type</Typography>
                    <Typography component="p">Free</Typography>
                  </Box>
                </Grid>
                <Grid item lg={3} md={3} sm={6} xs={12}>
                  <Box>
                    <Typography component="h5">Certificate</Typography>
                    <Typography component="p">Yes</Typography>
                  </Box>
                </Grid>
                <Grid item lg={3} md={3} sm={6} xs={12}>
                  <Box>
                    <Typography component="h5">Price</Typography>
                    <Typography component="p">$10</Typography>
                  </Box>
                </Grid>
                <Grid item lg={3} md={3} sm={6} xs={12}>
                  <Box>
                    <Typography component="h5">Level of Course</Typography>
                    <Typography component="p">Beginner</Typography>
                  </Box>
                </Grid>
                <Grid item lg={3} md={3} sm={6} xs={12}>
                  <Box>
                    <Typography component="h5">
                      Publisher/Author name
                    </Typography>
                    <Typography component="p">John</Typography>
                  </Box>
                </Grid>
                <Grid item lg={3} md={3} sm={6} xs={12}>
                  <Box>
                    <Typography component="h5">Curriculum</Typography>
                    <Typography component="p">Course Curriculum</Typography>
                  </Box>
                </Grid>
                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <Box>
                    <Typography component="h5">Description</Typography>
                    <Typography component="p">
                      Lorem ipsum dolor sit amet consectetur, adipisicing elit
                      dolor sit amet consectetur. Lorem ipsum dolor sit amet
                      consectetur, adipisicing elit dolor sit amet consectetur.
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
        <Card sx={{ mt: 4 }} className="cards">
          <h2 className="mn_hdng">Quiz</h2>
          <CardContent sx={{ p: 1 }}>
            <Grid container spacing={2} className="view_box">
              <Grid item xs={10} className="view_box_list">
                <Grid container spacing={3}>
                  <Grid item lg={12} md={12} sm={12} xs={12}>
                    <Box>
                      <Typography component="h5">1. Question</Typography>
                      <Typography component="p">
                        What is the meaning of UI/UX design?
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item lg={6} md={6} sm={6} xs={12}>
                    <Box>
                      <Typography component="h5">Option 1</Typography>
                      <Typography component="p">
                        User Interface and User Experience
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item lg={6} md={6} sm={6} xs={12}>
                    <Box>
                      <Typography component="h5">Option 2</Typography>
                      <Typography component="p">
                        User Interface and User Experience
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item lg={6} md={6} sm={6} xs={12}>
                    <Box>
                      <Typography component="h5">Option 3</Typography>
                      <Typography component="p">
                        User Interface and User Experience
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item lg={6} md={6} sm={6} xs={12}>
                    <Box>
                      <Typography component="h5">Option 4</Typography>
                      <Typography component="p">
                        User Interface and User Experience
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item lg={6} md={6} sm={6} xs={12}>
                    <Box>
                      <Typography component="h5">Correct Answer</Typography>
                      <Typography component="p">
                        User Interface and User Experience
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item lg={12} md={12} sm={12} xs={12}>
                    <Box>
                      <Typography component="h5">2. Question</Typography>
                      <Typography component="p">
                        What is the meaning of UI/UX design?
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item lg={6} md={6} sm={6} xs={12}>
                    <Box>
                      <Typography component="h5">Option 1</Typography>
                      <Typography component="p">
                        User Interface and User Experience
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item lg={6} md={6} sm={6} xs={12}>
                    <Box>
                      <Typography component="h5">Option 2</Typography>
                      <Typography component="p">
                        User Interface and User Experience
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item lg={6} md={6} sm={6} xs={12}>
                    <Box>
                      <Typography component="h5">Option 3</Typography>
                      <Typography component="p">
                        User Interface and User Experience
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item lg={6} md={6} sm={6} xs={12}>
                    <Box>
                      <Typography component="h5">Option 4</Typography>
                      <Typography component="p">
                        User Interface and User Experience
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item lg={6} md={6} sm={6} xs={12}>
                    <Box>
                      <Typography component="h5">Correct Answer</Typography>
                      <Typography component="p">
                        User Interface and User Experience
                      </Typography>
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </div>
    </MainContainer>
  );
};

export default CourseDetails;
