import {
  Box,
  Card,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import React from "react";

import { useNavigate } from "react-router-dom";
import VisibilityIcon from "@mui/icons-material/Visibility";

const ChallengeHistory = () => {
  const navigate = useNavigate();
  return (
    <Card sx={{ mt: 4 }} className="cards">
      <Box className="custom_tabs">
        <h2 className="mn_hdng">Challenge History</h2>
        <TableContainer className="table_container">
          <Box className="heading"></Box>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell align="center">S.No</TableCell>
                <TableCell>Title</TableCell>
                <TableCell>Challenge Points</TableCell>

                <TableCell align="center">Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell align="center">{1}</TableCell>
                <TableCell>Challenge Title</TableCell>
                <TableCell>10</TableCell>

                <TableCell align="center">
                  <Box className="table_actions">
                    <IconButton
                      onClick={() =>
                        navigate("/manage-users/details/challenge")
                      }
                    >
                      <VisibilityIcon />
                    </IconButton>
                    {/* <IconButton onClick={() => navigate("/manage-ChallengeHistory/add")}>
                      <ModeEditIcon />
                    </IconButton> */}
                    {/* <IconButton>
                      <DeleteIcon />
                    </IconButton> */}
                  </Box>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </Card>
  );
};

export default ChallengeHistory;
