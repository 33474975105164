import { useLocation, useNavigate, useParams } from "react-router-dom";
import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  Typography,
} from "@mui/material";

import MainContainer from "../../layout/MainContainer";

const QueryDetails = () => {
  const navigate = useNavigate();

  const location = useLocation();

  return (
    <MainContainer>
      <div className="main_loyout">
        <div className="dashboard">
          <h1>Query Details</h1>
          <Button
            className="btn btn_primary"
            onClick={() => {
              navigate("/customer-support");
            }}
          >
            Back
          </Button>
        </div>
        <Card className="cards">
          <CardContent sx={{ p: 1 }}>
            <Grid container spacing={2} className="view_box">
              <Grid item xs={10} className="view_box_list">
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <Box>
                      <Typography component="h2">User Details</Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={4}>
                    <Box>
                      <Typography component="h5">Username</Typography>
                      <Typography component="p">Abdul Hussain</Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={4}>
                    <Box>
                      <Typography component="h5">Email</Typography>
                      <Typography component="p">abdul@yopmail.com</Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={4}>
                    <Box>
                      <Typography component="h5">Contact No</Typography>
                      <Typography component="p">
                        +971 45454543343
                        {/* {" "}
                        {queryDetail?.countryCode
                          ? (queryDetail?.countryCode.includes("+")
                              ? ""
                              : "+") + queryDetail?.countryCode
                          : null}{" "}
                        {queryDetail?.phone || "-"} */}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={12}>
                    <Box>
                      <Typography component="h2">Query Details</Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={3}>
                    <Box>
                      <Typography component="h5">Issue Type</Typography>
                      <Typography component="p">Issue Type</Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={3}>
                    <Box>
                      <Typography component="h5">Query Status</Typography>
                      <Typography component="p">Resolved</Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={12}>
                    <Box>
                      <Typography component="h5">Description</Typography>
                      <Typography component="p">
                        It is a long established fact that a reader will be
                        distracted by the readable content of a page when
                        looking at its layout. The point of using Lorem Ipsum is
                        that it has a more-or-less normal distribution of
                        letters, as opposed to using 'Content here, content
                        here'
                      </Typography>
                    </Box>
                  </Grid>

                  <Grid item xs={12}>
                    <Box>
                      <Typography component="h5">Revert</Typography>
                      <Typography component="p">
                        It is a long established fact that a reader will be
                        distracted by the readable content of a page when
                        looking at its layout. The point of using Lorem Ipsum is
                        that it has a more-or-less normal distribution of
                        letters, as opposed to using 'Content here, content
                        here'
                      </Typography>
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </div>
    </MainContainer>
  );
};

export default QueryDetails;
