// import React, { useState } from "react";

// const Quiz = () => {
//   const [questions, setQuestions] = useState<
//     { question: string; answers: string[] }[]
//   >([]);

//   const handleAddQuestion = (newQuestion: {
//     question: string;
//     answers: string[];
//   }) => {
//     setQuestions([...questions, newQuestion]);
//   };
//   const [question, setQuestion] = useState("");
//   const [answers, setAnswers] = useState(["", "", "", ""]);

//   const handleAnswerChange = (index: number, value: string) => {
//     const newAnswers = [...answers];
//     newAnswers[index] = value;
//     setAnswers(newAnswers);
//   };

//   const handleSubmit = () => {
//     const newQuestion = { question, answers };
//     handleAddQuestion(newQuestion);
//     // Reset the form after submission
//     setQuestion("");
//     setAnswers(["", "", "", ""]);
//   };

//   return (
//     <>
//       <div>
//         <label>
//           Question:
//           <input
//             type="text"
//             value={question}
//             onChange={(e) => setQuestion(e.target.value)}
//           />
//         </label>
//         <br />
//         {answers.map((answer, index) => (
//           <div key={index}>
//             <label>
//               Answer {index + 1}:
//               <input
//                 type="text"
//                 value={answer}
//                 onChange={(e) => handleAnswerChange(index, e.target.value)}
//               />
//             </label>
//           </div>
//         ))}
//         <br />
//         <button onClick={handleSubmit}>Add Question</button>
//       </div>
//       <div>
//         <h2>Quiz</h2>
//         {questions.map((question, index) => (
//           <div key={index}>
//             <p>{question.question}</p>
//             <ul>
//               {question.answers.map((answer, i) => (
//                 <li key={i}>{answer}</li>
//               ))}
//             </ul>
//           </div>
//         ))}
//       </div>
//     </>
//   );
// };

// export default Quiz;
import React, { useState } from "react";
import {
  Grid,
  TextField,
  Button,
  Typography,
  Paper,
  List,
  ListItem,
} from "@mui/material";

const Quiz = () => {
  const [questions, setQuestions] = useState<
    { question: string; answers: string[] }[]
  >([]);

  const handleAddQuestion = (newQuestion: {
    question: string;
    answers: string[];
  }) => {
    setQuestions([...questions, newQuestion]);
  };

  const [question, setQuestion] = useState("");
  const [answers, setAnswers] = useState(["", "", "", ""]);

  const handleAnswerChange = (index: number, value: string) => {
    const newAnswers = [...answers];
    newAnswers[index] = value;
    setAnswers(newAnswers);
  };

  const handleSubmit = () => {
    const newQuestion = { question, answers };
    handleAddQuestion(newQuestion);
    // Reset the form after submission
    setQuestion("");
    setAnswers(["", "", "", ""]);
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={5}>
        <Paper elevation={3} style={{ padding: "20px" }}>
          <Typography variant="h6">Add Question</Typography>
          <TextField
            placeholder="Question"
            type="text"
            value={question}
            onChange={(e) => setQuestion(e.target.value)}
            fullWidth
            margin="normal"
          />
          {answers.map((answer, index) => (
            <TextField
              key={index}
              // label={`Answer ${index + 1}`}
              type="text"
              value={answer}
              onChange={(e) => handleAnswerChange(index, e.target.value)}
              fullWidth
              margin="normal"
              placeholder={`Answer ${index + 1}`}
            />
          ))}
          <TextField
            type="text"
            // onChange={(e) => handleAnswerChange(index, e.target.value)}
            fullWidth
            margin="normal"
            placeholder="Correct Answer"
          />
          <Button variant="contained" color="primary" onClick={handleSubmit}>
            Add Question
          </Button>
        </Paper>
      </Grid>
      <Grid item xs={7}>
        <Paper elevation={3} style={{ padding: "20px" }}>
          <Typography variant="h6">Quiz</Typography>
          <List>
            {questions.map((question, index) => (
              <ListItem key={index} style={{ display: "block" }}>
                <Typography variant="subtitle1">{question.question}</Typography>
                <List>
                  {question.answers.map((answer, i) => (
                    <>
                      <ListItem key={i}>{answer}</ListItem>
                    </>
                  ))}
                </List>
              </ListItem>
            ))}
          </List>
        </Paper>
      </Grid>
    </Grid>
  );
};

export default Quiz;
