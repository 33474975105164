import React from "react";
import MainContainer from "../../layout/MainContainer";
import {
  Box,
  Button,
  Card,
  IconButton,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
} from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";

import DeleteIcon from "@mui/icons-material/Delete";

import { useNavigate } from "react-router-dom";

const ManageReviews = () => {
  const navigate = useNavigate();
  const label = { inputProps: { "aria-label": "Switch demo" } };

  return (
    <MainContainer>
      <div className="main_loyout">
        <div className="dashboard">
          <h1>Manage Community Forum</h1>
        </div>
        <Card sx={{ mt: 4 }} className="cards">
          <Box className="custom_tabs">
            <TableContainer className="table_container">
              <Box className="heading"></Box>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell align="center">S.No</TableCell>
                    <TableCell align="center">Date</TableCell>

                    <TableCell align="center">Course Name</TableCell>

                    <TableCell align="center">User name</TableCell>
                    <TableCell align="center">Query</TableCell>
                    <TableCell>Actions</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell align="center">{1}</TableCell>
                    <TableCell align="center">25/12/2003</TableCell>

                    <TableCell align="center">New Course</TableCell>
                    <TableCell align="center">John</TableCell>
                    <TableCell>Lorem Ipsum .....</TableCell>
                    {/* <TableCell>
                      <Switch {...label} defaultChecked />
                    </TableCell> */}

                    <TableCell>
                      <Box className="table_actions">
                        <IconButton>
                          <VisibilityIcon
                            onClick={() =>
                              navigate("/manage-community-forum/details")
                            }
                          />
                        </IconButton>
                        <IconButton>
                          <DeleteIcon />
                        </IconButton>
                      </Box>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </Card>
      </div>
    </MainContainer>
  );
};

export default ManageReviews;
