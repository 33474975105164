import {
  Box,
  Card,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import React from "react";

const PurchaseHistory = () => {
  return (
    <Card sx={{ mt: 4 }} className="cards">
      <Box className="custom_tabs">
        <h2 className="mn_hdng">Purchase History</h2>
        <TableContainer className="table_container">
          <Box className="heading"></Box>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell align="center">S.No</TableCell>
                <TableCell align="center">Course Name</TableCell>
                <TableCell>Purchase Date</TableCell>
                <TableCell>Amount</TableCell>
                <TableCell>Validity</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell align="center">{1}</TableCell>
                <TableCell align="center">Staff Pick</TableCell>
                <TableCell>22/12/2023</TableCell>
                <TableCell>$100</TableCell>
                <TableCell>3 months</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </Card>
  );
};

export default PurchaseHistory;
