import { Box, Card } from "@mui/material";
import React, { useState } from "react";

const ProgressHistory = () => {
  const initialVideoList = [
    {
      id: 1,
      title: "Course 1",
      progress: 30,
      videoUrl: "/static/videos/video1.mp4",
    },
    {
      id: 2,
      title: "Course 2",
      progress: 60,
      videoUrl: "/static/videos/video2.mp4",
    },
    // Add more videos as needed
  ];
  const [videoList, setVideoList] = useState(initialVideoList);
  const updateProgress = (videoId: number, newProgress: string) => {
    setVideoList((prevList: any) =>
      prevList.map((video: any) =>
        video.id === videoId ? { ...video, progress: newProgress } : video
      )
    );
  };

  return (
    <Card sx={{ mt: 4 }} className="cards">
      <Box className="custom_tabs">
        <h2 className="mn_hdng">Progress History </h2>
        <ul>
          {videoList.map((video) => (
            <li key={video.id}>
              <strong>{video.title}</strong>
              {/* <figure>
                <video
                  autoPlay={false}
                  muted
                  controls
                  // loop
                  // playsInline
                  className="hm_vd"
                  preload="yes"
                >
                  <source src={video?.videoUrl} type="video/mp4" />
                </video>
              </figure> */}

              <p>Progress: {video.progress}%</p>
              <input
                type="range"
                value={video.progress}
                // onChange={(e) => updateProgress(video.id, e.target.value)}
                min="0"
                max="100"
                step="1"
              />
            </li>
          ))}
        </ul>
      </Box>
    </Card>
  );
};

export default ProgressHistory;
