import React from "react";
import MainContainer from "../../layout/MainContainer";
import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { useNavigate } from "react-router-dom";

const RevenueDetails = () => {
  const navigate = useNavigate();
  return (
    <MainContainer>
      <div className="main_loyout">
        <div className="dashboard">
          <h1 className="mn_hdng">Payment Details</h1>
          <Button
            className="btn btn_primary"
            onClick={() => {
              navigate("/manage-revenue");
            }}
          >
            Back
          </Button>
        </div>
        <Card className="cards">
          <CardContent sx={{ p: 1 }}>
            <Grid container spacing={2} className="view_box">
              <Grid item xs={10} className="view_box_list">
                <Grid container spacing={3}>
                  <Grid item lg={12} md={12} sm={12} xs={12}>
                    <Box>
                      <Typography component="h5">User name</Typography>
                      <Typography component="p">Abdul Hussain</Typography>
                    </Box>
                  </Grid>
                  <Grid item lg={6} md={6} sm={6} xs={12}>
                    <Box>
                      <Typography component="h5">Email</Typography>
                      <Typography component="p">abdul@yopmail.com</Typography>
                    </Box>
                  </Grid>
                  <Grid item lg={6} md={6} sm={6} xs={12}>
                    <Box>
                      <Typography component="h5">Phone Number</Typography>
                      <Typography component="p">+971 67654548676</Typography>
                    </Box>
                  </Grid>
                  <Grid item lg={6} md={6} sm={6} xs={12}>
                    <Box>
                      <Typography component="h5">Payment Date</Typography>
                      <Typography component="p">25/12/2024</Typography>
                    </Box>
                  </Grid>
                  <Grid item lg={6} md={6} sm={6} xs={12}>
                    <Box>
                      <Typography component="h5">Payment Received</Typography>
                      <Typography component="p">$100</Typography>
                    </Box>
                  </Grid>
                  <Grid item lg={6} md={6} sm={6} xs={12}>
                    <Box>
                      <Typography component="h5">Payment Mode</Typography>
                      <Typography component="p">Credit Card</Typography>
                    </Box>
                  </Grid>
                  <Grid item lg={6} md={6} sm={6} xs={12}>
                    <Box>
                      <Typography component="h5">
                        Subscription Plan Bought
                      </Typography>
                      <Typography component="p">Plan Name</Typography>
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
        {/* <Card sx={{ mt: 4 }} className="cards">
          <Box className="custom_tabs">
            <h2 className="mn_hdng">User Response</h2>
            <TableContainer className="table_container">
              <Box className="heading"></Box>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell align="center">S.No</TableCell>
                    <TableCell align="center">Created On</TableCell>
                    <TableCell>User name</TableCell>
                    <TableCell>User Response</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell align="center">{1}</TableCell>
                    <TableCell align="center">23/12/2024</TableCell>
                    <TableCell>John</TableCell>
                    <TableCell> User Interface and User Experience</TableCell>
                  
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </Card> */}
      </div>
    </MainContainer>
  );
};

export default RevenueDetails;
