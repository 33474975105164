import React, { useEffect, useState } from "react";
import MainContainer from "../../layout/MainContainer";

import ModeEditIcon from "@mui/icons-material/ModeEdit";
import DeleteIcon from "@mui/icons-material/Delete";

import {
  Card,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
  Box,
  Switch,
  Button,
  Typography,
  Grid,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import SearchBar from "../../components/SearchBar";

const ManageSubscription = () => {
  const navigate = useNavigate();
  const [open, setOpen] = useState<boolean>(false);

  const [hidePermission, setHidePermission] = useState<
    Permissions | null | undefined
  >(null);
  const [page, setPage] = useState(1);

  const [totalCount, setTotalCount] = useState<number>(0);

  const label = { inputProps: { "aria-label": "Switch demo" } };

  return (
    <MainContainer>
      <div className="main_loyout">
        <div className="dashboard">
          <h1 className="mn_hdng">Manage Subscription </h1>
        </div>
        {/* <Card className="cards" style={{ marginBottom: "10px" }}></Card> */}
        <Card className="cards">
          <Box className="custom_tabs">
            <Box className="cards_header">
              <SearchBar />
              <Box className="cards_header_right">
                <Button
                  className="btn btn_primary"
                  onClick={() => navigate("/manage-subscriptions/add")}
                >
                  Add Subscription
                </Button>
              </Box>
            </Box>
            <TableContainer className="table_container">
              <Box className="heading"></Box>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell align="center">S.No</TableCell>
                    <TableCell> Name</TableCell>
                    <TableCell> Validity (days)</TableCell>
                    <TableCell> Price</TableCell>
                    {/* <TableCell>Number of Bank Accounts </TableCell> */}

                    <TableCell> Status</TableCell>
                    <TableCell>Actions</TableCell>
                  </TableRow>
                </TableHead>

                <TableBody>
                  <TableRow>
                    <TableCell align="center"> {1}</TableCell>
                    <TableCell>Subscription Name</TableCell>
                    <TableCell align="center">60 days</TableCell>
                    <TableCell align="center">$10</TableCell>
                    {/* <TableCell align="center">
                            {row?.noOfAccounts || ""}{" "}
                          </TableCell> */}

                    <TableCell>
                      <Switch
                        {...label}
                        defaultChecked
                        // checked={row?.isActive}
                        // // onClick={() =>
                        //   updateStatus(row?._id, row?.isActive)
                        // }
                        size="small"
                      />
                    </TableCell>
                    <TableCell>
                      <Box className="table_actions">
                        {/* {hidePermission?.isEdit ||
                              userData?.role === "ADMIN" ? ( */}
                        <IconButton
                          onClick={() =>
                            navigate(`/manage-subscriptions/add`, {
                              state: { type: "edit" },
                            })
                          }
                        >
                          <ModeEditIcon />
                        </IconButton>
                        {/* ) : null} */}
                        {/* {hidePermission?.isDelete ||
                              userData?.role === "ADMIN" ? ( */}
                        <IconButton
                        // onClick={() => {
                        //   setOpen(true);
                        //   setSelectedId(row?._id);
                        // }}
                        >
                          <DeleteIcon />
                        </IconButton>
                        {/* ) : null} */}
                      </Box>
                    </TableCell>
                  </TableRow>
                </TableBody>
                {/* );
                  })
                ) : (
                  <TableBody>
                    <TableCell align="center" colSpan={6}>
                      No Subscription Plan Found
                    </TableCell>
                  </TableBody>
                )} */}
              </Table>
            </TableContainer>
          </Box>
        </Card>
      </div>
    </MainContainer>
  );
};

export default ManageSubscription;
