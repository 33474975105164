import React from "react";
import MainContainer from "../../layout/MainContainer";
import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import DeleteIcon from "@mui/icons-material/Delete";

const CommunityDetails = () => {
  const navigate = useNavigate();

  return (
    <MainContainer>
      <div className="main_loyout">
        <div className="dashboard">
          <h1 className="mn_hdng">Community Details</h1>
          <Button
            className="btn btn_primary"
            onClick={() => {
              navigate("/manage-community-forum");
            }}
          >
            Back
          </Button>
        </div>
        <Card className="cards">
          <CardContent sx={{ p: 1 }}>
            <Grid item xs={10} className="view_box_list">
              <Grid container spacing={3}>
                <Grid item lg={3} md={3} sm={6} xs={12}>
                  <Box>
                    <Typography component="h5">Course Name</Typography>
                    <Typography component="p">Course Name</Typography>
                  </Box>
                </Grid>
                <Grid item lg={3} md={3} sm={6} xs={12}>
                  <Box>
                    <Typography component="h5">User name</Typography>
                    <Typography component="p">John</Typography>
                  </Box>
                </Grid>

                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <Box>
                    <Typography component="h5">Query</Typography>
                    <Typography component="p">
                      {" "}
                      Lorem ipsum dolor sit amet consectetur, adipisicing elit
                      dolor sit amet consectetur. Lorem ipsum dolor sit amet
                      consectetur, adipisicing elit dolor sit amet consectetur.
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
        <Card sx={{ mt: 4 }} className="cards">
          <Box className="custom_tabs">
            <h2 className="mn_hdng">User Response</h2>
            <TableContainer className="table_container">
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell align="center">S.No</TableCell>
                    <TableCell align="center">Date</TableCell>
                    <TableCell align="center">User name</TableCell>
                    <TableCell align="center">Comment</TableCell>
                    <TableCell>Actions</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell align="center">{1}</TableCell>
                    <TableCell align="center">24/12/2024</TableCell>
                    <TableCell align="center">John</TableCell>
                    <TableCell>Lorem Ipsum .....</TableCell>
                    {/* <TableCell>
                      <Switch {...label} defaultChecked />
                    </TableCell> */}

                    <TableCell>
                      <Box className="table_actions">
                        {/* <IconButton>
                          <VisibilityIcon
                            onClick={() =>
                              navigate("/manage-community-forum/details")
                            }
                          />
                        </IconButton> */}
                        <IconButton>
                          <DeleteIcon />
                        </IconButton>
                      </Box>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </Card>
      </div>
    </MainContainer>
  );
};

export default CommunityDetails;
