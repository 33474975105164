import LoginPage from "./auth/login";
import DashBoardPage from "./dashBoard";
import ManageUsers from "./users";
import ForgotPasswordPage from "./auth/forgotPassword";
import VerifyOtp from "./auth/otp";
import Analytics from "./analytics";
import Faq from "./faq";
import ResetPassword from "./auth/resetPassword";
import Profile from "./profile";
import ManageCms from "./manageCms";
import ManageNotifications from "./manageNotification";
import AddNotification from "./manageNotification/add";
import CustomerSupport from "./customerSupport";
import ManageSubAdmin from "./manageSubAdmin";
import AddSubAdmin from "./manageSubAdmin/add";
import ManageCategories from "./manageCategories";
import AddCategories from "./manageCategories/add";
import RecievedNotifications from "./manageNotification/receivedNotification";
import ManageServiceProvider from "./manageServiceProvider";
import AddServiceProvider from "./manageServiceProvider/add";
import SubCategories from "./manageCategories/details";
import ChangePassword from "./auth/changePassword";
import ServiceProviderDetails from "./manageServiceProvider/details";
import ManageAmenities from "./manageAmenities";
import ManageListedProperties from "./manageListedProperties";
import PropertyDetails from "./manageListedProperties/details";
import EditProperties from "./manageListedProperties/edit";
import ManageBookings from "./manageBookings";
import BookingDetails from "./manageBookings/details";
import AddAmenities from "./manageAmenities/add";
import ManageReviews from "./manageReviews";
import ReviewDetails from "./manageReviews/details";
import ManageRevenue from "./revenueManagement";
import ManageCourses from "./manageCourses";
import AddCourse from "./manageCourses/add";
import ManageChallenges from "./manageChallenges";
import AddChallenge from "./manageChallenges/add";
import ManageQuestionnaire from "./manageQuestionnaire";
import AddQuestionnaire from "./manageQuestionnaire/add";
import ManageSubscription from "./manageSubscription";
import AddSubscriptionPlan from "./manageSubscription/add";
import QueryDetails from "./customerSupport/details";
import ManageSurveys from "./manageSurveys";
import AddSurvey from "./manageSurveys/add";
import SurveyDetails from "./manageSurveys/details";
import CommunityDetails from "./manageReviews/details";
import ChallengeDetails from "./manageChallenges/details";
import CourseDetails from "./manageCourses/details";
import RevenueDetails from "./revenueManagement/details";

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  LoginPage,
  DashBoardPage,
  ManageUsers,
  ForgotPasswordPage,
  VerifyOtp,
  Analytics,
  Faq,
  ResetPassword,
  Profile,
  ManageCms,
  ManageNotifications,
  AddNotification,
  CustomerSupport,
  ManageSubAdmin,
  AddSubAdmin,
  ManageCategories,
  AddCategories,
  RecievedNotifications,
  ManageServiceProvider,
  AddServiceProvider,
  SubCategories,
  ChangePassword,
  ServiceProviderDetails,
  ManageAmenities,
  ManageListedProperties,
  PropertyDetails,
  EditProperties,
  ManageBookings,
  BookingDetails,
  AddAmenities,
  ManageReviews,
  ReviewDetails,
  ManageRevenue,
  ManageCourses,
  AddCourse,
  ManageChallenges,
  AddChallenge,
  ManageQuestionnaire,
  AddQuestionnaire,
  ManageSubscription,
  AddSubscriptionPlan,
  QueryDetails,
  ManageSurveys,
  AddSurvey,
  SurveyDetails,
  ChallengeDetails,
  CommunityDetails,
  CourseDetails,
  RevenueDetails,
};
