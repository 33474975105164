import {
  Box,
  Card,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
} from "@mui/material";
import React from "react";
import { Rating } from "react-simple-star-rating";

const Review = () => {
  return (
    <Card sx={{ mt: 4 }} className="cards">
      <Box className="custom_tabs">
        <h2 className="mn_hdng">Reviews and Ratings </h2>
        <TableContainer className="table_container">
          <Box className="heading"></Box>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell align="center">S.No</TableCell>
                <TableCell>Course Name</TableCell>
                <TableCell>Ratings</TableCell>
                <TableCell>Reviews</TableCell>
                {/* <TableCell>Status</TableCell> */}
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell align="center">{1}</TableCell>
                <TableCell>Staff Pick</TableCell>
                <TableCell>
                  {" "}
                  <Rating
                    initialValue={4}
                    // onClick={(newRating: number) =>
                    //   setratingCount(newRating)
                    // }
                    iconsCount={5}
                    size={23}
                    allowHover={false}
                    readonly
                    // transition={true}
                  />
                </TableCell>
                <Tooltip
                  title="Lorem ipsum dolor sit amet consectetur, adipisicing elit
            dolor sit amet consectetur. Lorem ipsum dolor sit amet
            consectetur, adipisicing elit dolor sit amet"
                >
                  <TableCell className="td">
                    Lorem ipsum dolor sit amet consectetur, adipisicing elit
                    dolor sit amet consectetur. Lorem ipsum dolor sit amet
                    consectetur, adipisicing elit dolor sit amet consectetur.
                  </TableCell>
                </Tooltip>
                {/* <TableCell>
                <Switch {...label} defaultChecked size="small" />
              </TableCell> */}
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </Card>
  );
};

export default Review;
